import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { CustomError } from 'ts-custom-error';
import { getBootstrapTranslations } from '@leon-hub/bootstrap-translations';
import { isCauseSupported } from './isCauseSupported';
import normalizeError from './normalizeError';
let AbstractError = class AbstractError extends CustomError {
    isInteractive() {
        return this.interactive;
    }
    addLogMetaData(key, value) {
        this.metaData[key] = value;
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    toJSON() {
        return this.getSerializable();
    }
    toString() {
        const stack = this.stack ? `\n${this.stack}` : '';
        return `${this.name}: ${this.message}${stack}`;
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    getSerializable() {
        return {
            name: this.name,
            message: this.message,
            code: this.code.toString(),
            meta: this.metaData,
            stack: this.stack,
            silent: this.silent,
            payload: this.payload,
            interactive: this.interactive
        };
    }
    clone() {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        return Object.create(Object.getPrototypeOf(this), Object.getOwnPropertyDescriptors(this));
    }
    // eslint-disable-next-line sonarjs/cognitive-complexity
    constructor({ log, ...options }){
        const rawCause = options.cause ?? options.originalError;
        const cause = void 0 === rawCause ? void 0 : normalizeError(rawCause);
        const causeSupported = isCauseSupported();
        super(options.message ?? cause?.message ?? getBootstrapTranslations().WEB2_TECHNICAL_ERROR, causeSupported ? {
            cause
        } : void 0);
        _define_property(this, "silent", void 0);
        _define_property(this, "logLevel", 'ERROR');
        _define_property(this, "code", void 0);
        _define_property(this, "payload", void 0);
        _define_property(this, "metaData", {});
        _define_property(this, "cause", void 0);
        _define_property(this, "interactive", void 0);
        this.cause = this.cause ?? cause;
        if (causeSupported) Error.captureStackTrace?.(this, cause?.constructor ?? new.target);
        else if (cause) {
            this.cause = cause;
            const stack = this.cause?.stack;
            if (this.stack) {
                if (stack) this.stack = `${this.stack}\nCaused by: ${stack}`;
            } else this.stack = stack;
        }
        this.logLevel = options.logLevel ?? this.logLevel;
        this.code = options.code;
        this.silent = options.silent ?? false;
        this.payload = options.payload;
        this.interactive = !!options.interactive;
        if (log) this.addLogMetaData('log', log);
    }
};
export { AbstractError as default };
