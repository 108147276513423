import { isCasinoIconName } from './isCasinoIconName';
import { isIconName } from './isIconName';
import { isSlottIconName } from './isSlottIconName';
import { isSlottCasinoIconName } from './isSlottCasinoIconName';
import { isVirtualSportIconName } from './isVirtualSportIconName';
export function isAnyIconName(value) {
    if (isCasinoIconName(value)) return true;
    "1";
    if (isSlottCasinoIconName(value)) return true;
    if (isSlottIconName(value)) return true;
    if (isVirtualSportIconName(value)) return true;
    return isIconName(value);
}
