import { getLocationHref, getLocationOrigin } from '@leon-hub/service-locator-env';
import { QueryParameterGroupName, QueryParameterName } from './enums';
import getQueryParameter from './getQueryParameter';
function getAffiliateParameters() {
    const affiliateParameters = [
        QueryParameterName.WM,
        QueryParameterName.SUB_ID,
        QueryParameterName.SUB_ID2,
        QueryParameterName.SUB_ID3,
        QueryParameterName.SUB_ID4,
        QueryParameterName.SUB_ID5,
        QueryParameterName.CLICK_ID,
        QueryParameterName.WM_ZONE,
        QueryParameterName.BONUS_TAG,
        QueryParameterName.IA_BONUS_TAG,
        QueryParameterName.LOCALE,
        QueryParameterName.IA_LOCALE
    ];
    const parameters = [];
    for (const parameter of affiliateParameters)parameters.push({
        key: parameter,
        type: 'string',
        value: '',
        present: false,
        group: QueryParameterGroupName.AFFILIATE,
        apiHeaderName: `X-${parameter.replace(/_/g, '-').toUpperCase()}`
    });
    return parameters;
}
function getBonusParameters() {
    return [
        {
            key: QueryParameterName.BONUS_CODE,
            type: 'string',
            value: '',
            present: false,
            group: QueryParameterGroupName.BONUS
        }
    ];
}
function getBanerIdParameters() {
    return [
        {
            key: QueryParameterName.BANNER_ID,
            type: 'string',
            value: '',
            present: false,
            group: QueryParameterGroupName.BONUS
        }
    ];
}
function getDefaultParameters() {
    return [
        {
            key: QueryParameterName.VUE_DEVTOOLS,
            type: 'boolean',
            value: false,
            present: false,
            group: QueryParameterGroupName.APP
        },
        {
            key: QueryParameterName.VUE_PERFORMANCE,
            type: 'boolean',
            value: false,
            present: false,
            group: QueryParameterGroupName.APP
        },
        {
            key: QueryParameterName.VUE_SILENT,
            type: 'boolean',
            value: true,
            present: false,
            group: QueryParameterGroupName.APP
        }
    ];
}
function getMarketingParameters() {
    const marketingParameters = [
        QueryParameterName.UTM_SOURCE,
        QueryParameterName.UTM_MEDIUM,
        QueryParameterName.UTM_CAMPAIGN,
        QueryParameterName.UTM_TERM,
        QueryParameterName.UTM_CONTENT
    ];
    const parameters = [];
    for (const parameter of marketingParameters)parameters.push({
        key: parameter,
        type: 'string',
        value: '',
        present: false,
        group: QueryParameterGroupName.MARKETING,
        apiHeaderName: `X-${parameter.replace(/_/g, '-').toUpperCase()}`
    }, {
        key: QueryParameterName.RETENTION_ID,
        apiHeaderName: 'X-RETENTION-ID',
        type: 'string',
        value: '',
        present: false,
        useOnce: true,
        group: QueryParameterGroupName.MARKETING
    }, {
        key: QueryParameterName.APP_AD,
        type: 'string',
        value: '',
        present: false,
        group: QueryParameterGroupName.MARKETING
    }, {
        key: QueryParameterName.QTAG,
        apiHeaderName: 'X-QTAG',
        type: 'string',
        value: '',
        present: false,
        group: QueryParameterGroupName.MARKETING
    }, {
        key: QueryParameterName.REFTAG,
        apiHeaderName: 'X-REFTAG',
        type: 'string',
        value: '',
        present: false,
        group: QueryParameterGroupName.MARKETING
    });
    return parameters;
}
function getTestingParameters() {
    return [
        {
            key: QueryParameterName.TEST_CASE,
            type: 'string',
            value: '',
            present: false,
            group: QueryParameterGroupName.TESTING
        },
        {
            key: QueryParameterName.PRERENDER,
            type: 'number',
            value: 0,
            present: false,
            group: QueryParameterGroupName.TESTING
        },
        {
            key: QueryParameterName.PRERENDER_HOST,
            type: 'string',
            value: '',
            present: false,
            group: QueryParameterGroupName.TESTING
        },
        {
            key: QueryParameterName.PRERENDER_IP,
            type: 'string',
            value: '',
            present: false,
            group: QueryParameterGroupName.TESTING
        }
    ];
}
function isValidParameterItem(item, input) {
    let addItem = true;
    if (input) {
        if (input.group && (Array.isArray(input.group) && !input.group.includes(item.group) || !Array.isArray(input.group) && input.group !== item.group)) addItem = false;
        if (!input.showNotPresent && !item.present) addItem = false;
    } else if (!item.present) addItem = false;
    return addItem;
}
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function getQueryManager(initUrl) {
    let url = initUrl || getLocationHref();
    if (url.startsWith('/')) url = `${getLocationOrigin()}${url}`;
    const parameters = [];
    const searchParameters = new URL(url).searchParams;
    function applyUrlParameters() {
        for (const parameter of Object.values(parameters))Object.assign(parameter, {
            present: searchParameters.has(parameter.key)
        });
    }
    parameters.push(...getDefaultParameters(), ...getAffiliateParameters(), ...getBonusParameters(), ...getMarketingParameters(), ...getTestingParameters(), ...getBanerIdParameters());
    applyUrlParameters();
    function getParameters(input) {
        return parameters.reduce((accumulator, item)=>{
            let value = getQueryParameter(searchParameters, item.key, item.type, item.value);
            if ('boolean' == typeof value && !value && !input?.booleanAsString) value = '';
            if (isValidParameterItem(item, input)) return {
                ...accumulator,
                [item.key]: String(value)
            };
            return accumulator;
        }, {});
    }
    function getHeaderParameters(input) {
        return parameters.reduce((accumulator, item)=>{
            let value = getQueryParameter(searchParameters, item.key, item.type, item.value);
            if ('boolean' == typeof value && !value && !input?.booleanAsString) value = '';
            if (isValidParameterItem(item, input)) {
                if (item.useOnce) // eslint-disable-next-line no-param-reassign
                item.present = false;
                const key = item.apiHeaderName ? item.apiHeaderName : item.key.toUpperCase();
                return {
                    ...accumulator,
                    [key]: encodeURIComponent(String(value))
                };
            }
            return accumulator;
        }, {});
    }
    function getParameter(name) {
        let showNotPresent = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
        return getParameters({
            showNotPresent
        })[name] || '';
    }
    function getRootHeaderParameters() {
        return getHeaderParameters({
            group: [
                QueryParameterGroupName.AFFILIATE,
                QueryParameterGroupName.MARKETING
            ]
        });
    }
    return {
        getHeaderParameters,
        getParameters,
        getParameter,
        getRootHeaderParameters
    };
}
