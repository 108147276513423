import { _ as _define_property } from "@swc/helpers/_/_define_property";
import { AbstractError } from '@leon-hub/errors';
import { ApiErrorCode } from './ApiErrorCode';
export class ApiError extends AbstractError {
    setOperationName(operationName) {
        this.operationName = operationName;
    }
    getOperationName() {
        return this.operationName;
    }
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    getSerializable() {
        return {
            ...super.getSerializable(),
            responseCode: this.responseCode,
            request: this.request,
            response: this.response,
            operationName: this.operationName
        };
    }
    constructor(options){
        super({
            ...options,
            code: options?.code ?? ApiErrorCode.API_UNEXPECTED_ERROR
        });
        _define_property(this, "responseCode", void 0);
        _define_property(this, "request", void 0);
        _define_property(this, "response", void 0);
        _define_property(this, "operationName", void 0);
        this.responseCode = options?.responseCode ?? 0;
        this.request = options?.request;
        this.response = options?.response;
        this.operationName = options?.operationName;
    }
}
