let cache;
export const isCauseSupported = ()=>{
    if (void 0 === cache) try {
        const testError = new Error(void 0, {
            cause: new Error()
        });
        cache = void 0 !== testError.cause;
    } catch  {
        cache = false;
    }
    return cache;
};
