import { AbstractError } from '@leon-hub/errors';
import { AppErrorCode } from './AppErrorCode';
export class ComponentError extends AbstractError {
    constructor(options){
        super({
            ...options,
            message: options?.prefix ? `${options.prefix}: ${options.message}` : options?.message,
            code: new AppErrorCode('COMPONENT_ERROR')
        });
        if (!options) return;
        const vm = options.vm ?? null;
        if (vm?.$props) {
            this.addLogMetaData('props', vm.$props);
            this.addLogMetaData('info', options.info);
            this.addLogMetaData('name', vm.$options.name);
        }
    }
}
