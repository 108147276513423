/* eslint-disable max-len */ import { Locale } from '@leon-hub/locale';
const i18nBootstrapTranslations = {
    DEFAULT: Object.freeze({
        WEB2_ERROR: 'Error',
        WEB2_SESSION_EXPIRED: 'Session expired',
        CONNECTION_ERROR: 'Connection error. Please check your internet connection and try again.',
        WEB2_TECHNICAL_ERROR: 'A technical error has occurred. Please reload the page or try again later.',
        JSP_PCL_FBOT_CLOSE: 'Close',
        JS_CAPTION_ATTENTION: 'Attention',
        WEB2_DO_LOGIN: 'Log In',
        JSPNAV_USTAT_REGISTER: 'Sign Up',
        WEB2_ERROR_503_TITLE_V2: 'Our website is temporarily unavailable',
        WEB2_ERROR_503_TEXT1_V2: 'We are currently undergoing scheduled system maintenance.',
        WEB2_ERROR_503_TEXT2_V2: 'Please try again soon.',
        WEB2_ERROR_503_TEXT3_V2: 'We apologise for the inconvenience.',
        WEB2_ERROR_503_BTN_UPDATE_V2: 'Refresh',
        WEB2_HELP_EMAIL: "info@slott.com",
        WEB2_ERROR_BTN_GO_HOME: 'Back to main page',
        WEB2_ERROR_BTN_SUPPORT: 'Support',
        WEB2_ERROR_TEXT_SUPPORT: 'Have questions? We are always happy to help',
        WEB2_ERROR_404_TITLE: 'Page not found',
        WEB2_ERROR_404_MSG: 'The page you are looking for does not exist. It might have been moved or deleted.',
        WEB2_ERROR_403_TITLE: 'Access forbidden',
        WEB2_ERROR_403_MSG: 'The page you\'re trying to access has restricted access. Please go back to the homepage.',
        WEB2_CONNECTION_ERROR_CAPTION: 'Connection error',
        WEB2_CONNECTION_ERROR_DESCRIPTION: 'We were unable to connect to the server. Please check your internet connection and try again.',
        WEB2_CONNECTION_ERROR_BTN_RETRY: 'Retry',
        WEB2_MOBILE_LOADING_APK_TITLE: 'Updating the app',
        WEB2_MOBILE_LOADING_APK_MESSAGE: 'Please wait until the update is complete.',
        WEB2_MOBILE_UPDATE_APK_TITLE: 'App update available',
        WEB2_MOBILE_UPDATE_APK_MESSAGE: 'Please update to the latest version to continue using the app.',
        WEB2_MOBILE_UPDATE_APK_BTN_UPDATE: 'Update',
        WEB2_MOBILE_UPDATE_APK_BTN_POSTPONE: 'Later',
        WEB2_IP_BLOCKED_ERROR: 'This service is not available in your country.',
        WEB2_UPDATE_IOS_TITLE: 'iOS Update Required',
        WEB2_UPDATE_IOS_MESSAGE: 'Your current iOS version is not supported. To access the latest improvements, please update your device.',
        WEB2_UPDATE_IOS_BUTTON: 'Update iOS now',
        WEB2_UPDATE_ANDROID_TITLE: 'Android Update Required',
        WEB2_UPDATE_ANDROID_MESSAGE: 'Your current Android version is not supported. To access the latest improvements, please update your device.',
        WEB2_UPDATE_ANDROID_BUTTON: 'Update Android now'
    }),
    [Locale.FR_FR]: Object.freeze({
        WEB2_ERROR: 'Erreur',
        WEB2_SESSION_EXPIRED: 'La session a expiré',
        CONNECTION_ERROR: 'Erreur de connexion. Veuillez vérifier votre connexion Internet et réessayer.',
        WEB2_TECHNICAL_ERROR: 'Une erreur technique s\'est produite. Veuillez recharger la page ou réessayer plus tard.',
        JSP_PCL_FBOT_CLOSE: 'Fermer',
        JS_CAPTION_ATTENTION: 'Erreur',
        WEB2_DO_LOGIN: 'Se connecter',
        JSPNAV_USTAT_REGISTER: 'S\'inscrire',
        WEB2_ERROR_503_TITLE_V2: 'Notre site est momentanément indisponible',
        WEB2_ERROR_503_TEXT1_V2: 'Nous procédons actuellement à une maintenance planifiée du système.',
        WEB2_ERROR_503_TEXT2_V2: 'Veuillez réessayer plus tard.',
        WEB2_ERROR_503_TEXT3_V2: 'Nous nous excusons pour la gêne occasionnée.',
        WEB2_ERROR_503_BTN_UPDATE_V2: 'Rafraîchir',
        WEB2_HELP_EMAIL: "info@slott.com",
        WEB2_ERROR_BTN_GO_HOME: 'Retour à la page d\'accueil',
        WEB2_ERROR_BTN_SUPPORT: 'Support',
        WEB2_ERROR_TEXT_SUPPORT: 'Des questions? Nous sommes toujours heureux d\'aider',
        WEB2_ERROR_404_TITLE: 'Page non trouvée',
        WEB2_ERROR_404_MSG: 'La page que vous recherchez n\'existe pas. Elle a peut-être été déplacée ou supprimée.',
        WEB2_ERROR_403_TITLE: 'Accès interdit',
        WEB2_ERROR_403_MSG: 'La page à laquelle vous essayez d\'accéder a un accès restreint. Veuillez revenir à la page d\'accueil.',
        WEB2_CONNECTION_ERROR_CAPTION: 'Erreur de connexion',
        WEB2_CONNECTION_ERROR_DESCRIPTION: 'Nous n\'avons pas pu nous connecter au serveur. S\'il vous plaît, vérifiez votre connexion à internet et réessayez.',
        WEB2_CONNECTION_ERROR_BTN_RETRY: 'Réessayer',
        WEB2_MOBILE_LOADING_APK_TITLE: 'Mise à jour de l\'application',
        WEB2_MOBILE_LOADING_APK_MESSAGE: 'Veuillez patienter jusqu\'à ce que la mise à jour soit terminée.',
        WEB2_MOBILE_UPDATE_APK_TITLE: 'Mise à jour de l\'application disponible',
        WEB2_MOBILE_UPDATE_APK_MESSAGE: 'Veuillez télécharger la dernière version pour continuer à utiliser l\'application.',
        WEB2_MOBILE_UPDATE_APK_BTN_UPDATE: 'Mettre à jour',
        WEB2_MOBILE_UPDATE_APK_BTN_POSTPONE: 'Plus tard',
        WEB2_IP_BLOCKED_ERROR: 'Ce service n\'est pas disponible dans votre pays.',
        WEB2_UPDATE_IOS_TITLE: 'Mise à jour iOS requise',
        WEB2_UPDATE_IOS_MESSAGE: 'Votre version iOS actuelle n\'est pas prise en charge. Pour accéder aux dernières améliorations, veuillez mettre à jour votre appareil.',
        WEB2_UPDATE_IOS_BUTTON: 'Mettre à jour iOS maintenant',
        WEB2_UPDATE_ANDROID_TITLE: 'Mise à jour Android requise',
        WEB2_UPDATE_ANDROID_MESSAGE: 'Votre version actuelle d\'Android n\'est pas prise en charge. Pour accéder aux dernières améliorations, veuillez mettre à jour votre appareil.',
        WEB2_UPDATE_ANDROID_BUTTON: 'Mettre à jour Android maintenant'
    }),
    [Locale.RU_RU]: Object.freeze({
        WEB2_ERROR: 'Ошибка',
        WEB2_SESSION_EXPIRED: 'Сессия истекла',
        CONNECTION_ERROR: 'Ошибка соединения. Пожалуйста, проверьте соединение с интернетом и повторите попытку.',
        WEB2_TECHNICAL_ERROR: 'Произошла техническая ошибка. Обновите страницу или повторите попытку позже.',
        JSP_PCL_FBOT_CLOSE: 'Закрыть',
        JS_CAPTION_ATTENTION: 'Ошибка',
        WEB2_DO_LOGIN: 'Войти',
        JSPNAV_USTAT_REGISTER: 'Регистрация',
        WEB2_ERROR_503_TITLE_V2: 'Сайт Slott временно недоступен',
        WEB2_ERROR_503_TEXT1_V2: 'Проводятся технические работы по обновлению сайта.',
        WEB2_ERROR_503_TEXT2_V2: 'Совсем скоро мы снова будем с вами.',
        WEB2_ERROR_503_TEXT3_V2: 'Приносим извинения за неудобства.',
        WEB2_ERROR_503_BTN_UPDATE_V2: 'Обновить',
        WEB2_HELP_EMAIL: "info@slott.com",
        WEB2_ERROR_BTN_GO_HOME: 'На главную',
        WEB2_ERROR_BTN_SUPPORT: 'Помощь',
        WEB2_ERROR_TEXT_SUPPORT: 'Возникли трудности? Мы всегда рады помочь',
        WEB2_ERROR_404_TITLE: 'Страница не найдена',
        WEB2_ERROR_404_MSG: 'Возможно, ее не существует, или она была удалена.',
        WEB2_ERROR_403_TITLE: 'Доступ запрещен',
        WEB2_ERROR_403_MSG: 'Доступ к этой странице ограничен. Пожалуйста, вернитесь на Главную.',
        WEB2_CONNECTION_ERROR_CAPTION: 'Ошибка соединения',
        WEB2_CONNECTION_ERROR_DESCRIPTION: 'Произошла ошибка при подключении к серверу. Проверьте ваше интернет-соединение и повторите попытку.',
        WEB2_CONNECTION_ERROR_BTN_RETRY: 'Повторить',
        WEB2_MOBILE_LOADING_APK_TITLE: 'Идет обновление',
        WEB2_MOBILE_LOADING_APK_MESSAGE: 'Пожалуйста, дождитесь полной загрузки обновлений.',
        WEB2_MOBILE_UPDATE_APK_TITLE: 'Обновить приложение?',
        WEB2_MOBILE_UPDATE_APK_MESSAGE: 'В нашем приложении появились обновления. Установите их, чтобы продолжить игру.',
        WEB2_MOBILE_UPDATE_APK_BTN_UPDATE: 'Обновить',
        WEB2_MOBILE_UPDATE_APK_BTN_POSTPONE: 'Не сейчас',
        WEB2_IP_BLOCKED_ERROR: 'Сервис недоступен в вашей стране.',
        WEB2_UPDATE_IOS_TITLE: 'Обновите iOS',
        WEB2_UPDATE_IOS_MESSAGE: 'Текущая версия iOS не поддерживается. Пожалуйста, обновите ваше устройство, чтобы получить доступ ко всем функциям сайта.',
        WEB2_UPDATE_IOS_BUTTON: 'Обновить',
        WEB2_UPDATE_ANDROID_TITLE: 'Обновите Android',
        WEB2_UPDATE_ANDROID_MESSAGE: 'Текущая версия Android не поддерживается. Пожалуйста, обновите ваше устройство, чтобы получить доступ ко всем функциям сайта.',
        WEB2_UPDATE_ANDROID_BUTTON: 'Обновить'
    }),
    [Locale.PT_PT]: Object.freeze({
        WEB2_ERROR: 'Erro',
        WEB2_SESSION_EXPIRED: 'Sessão expirada',
        CONNECTION_ERROR: 'Erro de conexão. Por favor, verifique sua conexão com a internet e tente novamente.',
        WEB2_TECHNICAL_ERROR: 'Ocorreu um erro técnico. Por favor, recarregue a página ou tenta novamente mais tarde.',
        JSP_PCL_FBOT_CLOSE: 'Encerrar',
        JS_CAPTION_ATTENTION: 'Erro',
        WEB2_DO_LOGIN: 'Entrar',
        JSPNAV_USTAT_REGISTER: 'Registar',
        WEB2_ERROR_503_TITLE_V2: 'O NOSSO SITE ESTÁ TEMPORARIAMENTE INDISPONÍVEL',
        WEB2_ERROR_503_TEXT1_V2: 'Neste momento, estamos a realizar uma manutenção agendada do sistema.',
        WEB2_ERROR_503_TEXT2_V2: 'Pedimos desculpa pela inconveniência.',
        WEB2_ERROR_503_TEXT3_V2: 'Voltamos brevemente!',
        WEB2_ERROR_503_BTN_UPDATE_V2: 'Recarregar',
        WEB2_HELP_EMAIL: "info@slott.com",
        WEB2_ERROR_BTN_GO_HOME: 'Voltar à página principal',
        WEB2_ERROR_BTN_SUPPORT: 'Apoio ao Cliente',
        WEB2_ERROR_TEXT_SUPPORT: 'Tem dúvidas? Envie-nos um e-mail!',
        WEB2_ERROR_404_TITLE: 'Página não encontrada',
        WEB2_ERROR_404_MSG: 'A página que procura já não existe.',
        WEB2_ERROR_403_TITLE: 'Acesso proibido',
        WEB2_ERROR_403_MSG: 'A página que está a tentar aceder é de acesso restrito. Por favor, volte para a página principal',
        WEB2_CONNECTION_ERROR_CAPTION: 'Erro de conexão',
        WEB2_CONNECTION_ERROR_DESCRIPTION: 'Não conseguimos conectar ao servidor. Por favor, verifique a sua conexão de internet e tente novamente',
        WEB2_CONNECTION_ERROR_BTN_RETRY: 'Tente novamente',
        WEB2_MOBILE_LOADING_APK_TITLE: 'Atualizar a app',
        WEB2_MOBILE_LOADING_APK_MESSAGE: 'Por favor, aguarde até a atualização estar completa',
        WEB2_MOBILE_UPDATE_APK_TITLE: 'Atualização de app disponível',
        WEB2_MOBILE_UPDATE_APK_MESSAGE: 'Por favor, atualize a app para a versão mais recente para continuar a utilizar.',
        WEB2_MOBILE_UPDATE_APK_BTN_UPDATE: 'Atualização',
        WEB2_MOBILE_UPDATE_APK_BTN_POSTPONE: 'Mais tarde',
        WEB2_IP_BLOCKED_ERROR: 'Serviço indisponível no seu país.',
        WEB2_UPDATE_IOS_TITLE: 'Atualização de iOS necessária',
        WEB2_UPDATE_IOS_MESSAGE: 'A sua versão atual de iOS não é compatível. Para ter acesso às melhorias mais recentes, atualize o seu dispositivo.',
        WEB2_UPDATE_IOS_BUTTON: 'Atualize o iOS agora',
        WEB2_UPDATE_ANDROID_TITLE: 'Atualização de Android necessária',
        WEB2_UPDATE_ANDROID_MESSAGE: 'A sua versão atual de Android não é compatível. Para ter acesso às melhorias mais recentes, atualize o seu dispositivo.',
        WEB2_UPDATE_ANDROID_BUTTON: 'Atualize o Android agora'
    }),
    [Locale.IT_IT]: Object.freeze({
        WEB2_ERROR: 'Errore',
        WEB2_SESSION_EXPIRED: 'Sessione scaduta',
        CONNECTION_ERROR: 'Errore di connessione. Per favore controlla la tua connessione internet e riprova.',
        WEB2_TECHNICAL_ERROR: 'Si è verificato un errore tecnico. Per favore ricarica la pagina o riprova più tardi.',
        JSP_PCL_FBOT_CLOSE: 'Chiudi',
        JS_CAPTION_ATTENTION: 'Attenzione',
        WEB2_DO_LOGIN: 'Accedi',
        JSPNAV_USTAT_REGISTER: 'Registrati',
        WEB2_ERROR_503_TITLE_V2: 'Il nostro sito web è temporaneamente non disponibile',
        WEB2_ERROR_503_TEXT1_V2: 'Attualmente è in corso una manutenzione programmata del sistema.',
        WEB2_ERROR_503_TEXT2_V2: 'Si prega di riprovare a breve.',
        WEB2_ERROR_503_TEXT3_V2: 'Ci scusiamo per l\'inconveniente.',
        WEB2_ERROR_503_BTN_UPDATE_V2: 'Aggiorna',
        WEB2_HELP_EMAIL: "info@slott.com",
        WEB2_ERROR_BTN_GO_HOME: 'Torna alla pagina principale',
        WEB2_ERROR_BTN_SUPPORT: 'Assistenza',
        WEB2_ERROR_TEXT_SUPPORT: 'Hai domande? Siamo sempre felici di aiutarti',
        WEB2_ERROR_404_TITLE: 'Pagina non trovata',
        WEB2_ERROR_404_MSG: 'La pagina che stai cercando non esiste.',
        WEB2_ERROR_403_TITLE: 'Accesso vietato',
        WEB2_ERROR_403_MSG: 'L\'accesso alla pagina a cui stai tentando di accedere è limitato. Ti invitiamo a tornare alla homepage.',
        WEB2_CONNECTION_ERROR_CAPTION: 'Errore di connessione',
        WEB2_CONNECTION_ERROR_DESCRIPTION: 'Siamo spiacenti, questo servizio non è al momento disponibile. Per favore riprova più tardi.',
        WEB2_CONNECTION_ERROR_BTN_RETRY: 'Riprova',
        WEB2_MOBILE_LOADING_APK_TITLE: 'Aggiornamento dell\'app',
        WEB2_MOBILE_LOADING_APK_MESSAGE: 'Per favore attendi il completamento dell\'aggiornamento.',
        WEB2_MOBILE_UPDATE_APK_TITLE: 'Aggiornamento dell\'app disponibile',
        WEB2_MOBILE_UPDATE_APK_MESSAGE: 'Per favore aggiorna l\'app all\'ultima versione per continuare a usarla.',
        WEB2_MOBILE_UPDATE_APK_BTN_UPDATE: 'Aggiorna',
        WEB2_MOBILE_UPDATE_APK_BTN_POSTPONE: 'Più tardi',
        WEB2_IP_BLOCKED_ERROR: 'Questo servizio non è disponibile nel tuo Paese',
        WEB2_UPDATE_IOS_TITLE: 'Aggiornamento iOS richiesto',
        WEB2_UPDATE_IOS_MESSAGE: 'La versione corrente di iOS non è supportata. Per accedere agli ultimi miglioramenti, per favore aggiorna il tuo dispositivo.',
        WEB2_UPDATE_IOS_BUTTON: 'Aggiorna iOS ora',
        WEB2_UPDATE_ANDROID_TITLE: 'Aggiornamento Android richiesto',
        WEB2_UPDATE_ANDROID_MESSAGE: 'La versione corrente del tuo Android non è supportata. Per accedere agli ultimi miglioramenti, per favore aggiorna il tuo dispositivo.',
        WEB2_UPDATE_ANDROID_BUTTON: 'Aggiorna Android ora'
    })
};
export default i18nBootstrapTranslations;
