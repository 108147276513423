import { getRootSettingsInternational } from '@leon-hub/api-sdk';
import { useQueryManager } from 'web/src/modules/core/composables';
import useGraphqlClient from 'web/src/modules/core/services/api/useGraphqlClient';
export function makeRootSettingsRequest(silent) {
    const queryManager = useQueryManager();
    const headers = silent ? void 0 : queryManager.getRootHeaderParameters();
    return doRootSettingsRequest(headers, silent);
}
export function doRootSettingsRequest(headers, silent) {
    const apiClient = useGraphqlClient();
    "1";
    return getRootSettingsInternational(apiClient, (node)=>node.queries.system.rootSettings, {
        options: {}
    }, {
        headers,
        silent
    });
}
