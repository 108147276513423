/* eslint-disable global-require,max-len,sonarjs/no-duplicate-string,@typescript-eslint/consistent-indexed-object-style */ import getInitLocale from './getInitLocale';
let i18nBootstrapTranslations;
i18nBootstrapTranslations = {
    ...require('./slott/slott/bootstrapTranslationsSlott').default
};
export default function getBootstrapTranslations() {
    const locale = getInitLocale() || 'DEFAULT';
    return i18nBootstrapTranslations[locale] || i18nBootstrapTranslations.DEFAULT;
}
export function isBootstrapTranslationKey(key) {
    return Object.prototype.hasOwnProperty.call(getBootstrapTranslations(), key);
}
